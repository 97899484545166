/* Template Name: ZipLaneRx - Doctor Appointment Booking System
   Author: Shreethemes
   E-mail: support@shreethemes.in
   Created: January 2021
   Version: 1.4.0
   File Description: Main Css file of the template
*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
body {
  font-family: "Inter", sans-serif;
  overflow-x: hidden !important;
  font-size: 0.9375rem;
  color: #212529;
  background-color: #ffffff;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  font-family: var(--bs-font-sans-serif);
  line-height: 1.5 !important;
  font-weight: 600 !important;
}

::selection {
  background: rgba(57, 108, 240, 0.9);
  color: #ffffff;
}

a {
  text-decoration: none !important;
}

p {
  line-height: 1.6;
}

#topnav {
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 1030;
  background-color: transparent;
  border: 0;
  transition: all 0.5s ease;
}
#topnav .logo {
  float: left;
  color: #212529 !important;
  outline: none;
}
#topnav .logo .l-dark,
#topnav .logo .logo-dark-mode {
  display: none;
}
#topnav .logo .l-light,
#topnav .logo .logo-light-mode {
  display: inline-block;
}
#topnav .has-submenu.active a {
  color: #ffffff;
}
#topnav .has-submenu.active .submenu li.active > a {
  color: #396cf0 !important;
}
#topnav .has-submenu.active.active .menu-arrow {
  border-color: #396cf0;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu .submenu .submenu-arrow {
  border: solid #212529;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(-45deg);
  position: absolute;
  right: 20px;
  top: 14px;
}
#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow, #topnav .has-submenu .submenu .has-submenu.active .submenu-arrow {
  border-color: #396cf0;
}
#topnav .navbar-toggle {
  border: 0;
  position: relative;
  width: 60px;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
#topnav .navbar-toggle .lines {
  width: 25px;
  display: block;
  position: relative;
  margin: 30px 10px 26px;
  height: 18px;
}
#topnav .navbar-toggle span {
  height: 2px;
  width: 100%;
  background-color: #212529;
  display: block;
  margin-bottom: 5px;
  transition: transform 0.5s ease;
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0;
}
#topnav .dropdowns {
  float: right;
  line-height: 68px;
  padding: 2.5px 0;
}
#topnav .dropdowns > li {
  line-height: initial;
}
#topnav .dropdowns .login-btn-primary {
  display: none;
}
#topnav .dropdowns .login-btn-light {
  display: inline-block;
}
#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  transform: rotate(45deg);
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  width: 100%;
  top: 6px;
  transform: rotate(-45deg);
}
#topnav .navbar-toggle.open span:hover {
  background-color: #396cf0;
}
#topnav .navbar-toggle:hover, #topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover, #topnav .navbar-toggle:focus {
  background-color: transparent;
}
#topnav .navigation-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}
#topnav .navigation-menu > li {
  float: left;
  display: block;
  position: relative;
  margin: 0 10px;
}
#topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
  color: #396cf0 !important;
}
#topnav .navigation-menu > li > a {
  display: block;
  color: #212529;
  font-size: 13px;
  background-color: transparent !important;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
  transition: all 0.5s;
  font-family: var(--bs-font-sans-serif);
  padding-left: 15px;
  padding-right: 15px;
}
#topnav .navigation-menu > li > a:hover, #topnav .navigation-menu > li > a:active {
  color: #396cf0;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border: solid #212529;
  border-radius: 0.5px;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  position: absolute;
  transition: all 0.5s;
  right: -1px;
  top: 32px;
}
#topnav .navigation-menu .has-submenu:hover .menu-arrow {
  transform: rotate(225deg);
}
#topnav .menu-extras {
  float: right;
}
#topnav.scroll {
  background-color: #ffffff;
  border: none;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
#topnav.scroll .navigation-menu > li > a {
  color: #212529;
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  border-color: #212529;
}
#topnav.scroll .navigation-menu > li:hover > a, #topnav.scroll .navigation-menu > li.active > a {
  color: #396cf0;
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow, #topnav.scroll .navigation-menu > li.active > .menu-arrow {
  border-color: #396cf0;
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.defaultscroll.scroll.dark-menubar .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  background: #fff;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  color: #212529;
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #396cf0 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow, #topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  border-color: #396cf0 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a, #topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  color: #396cf0 !important;
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  border-color: #212529;
}
#topnav.nav-sticky .dropdowns .login-btn-primary {
  display: inline-block;
}
#topnav.nav-sticky .dropdowns .login-btn-light {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  font-weight: 600;
  font-size: 24px;
  padding: 0 0 6px;
  letter-spacing: 1px;
  line-height: 68px;
}

.search-bar {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}
.search-bar .menu-search form {
  position: relative;
}
.search-bar .menu-search input[type=text], .search-bar .searchform input[type=text] {
  box-shadow: none;
  padding: 12px 15px;
  height: 40px;
  font-size: 14px;
  display: block;
  width: 100%;
  outline: none !important;
  padding-right: 45px;
  opacity: 0.7;
}
.search-bar .menu-search input[type=submit], .search-bar .searchform input[type=submit] {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  width: 40px;
  height: 40px;
}
.search-bar .menu-search .searchform:after {
  content: "\f0349";
  position: absolute;
  font-family: "Material Design Icons";
  right: 16px;
  top: 12px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}

@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: flex;
    justify-content: center;
  }
  #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
    top: 15px;
    margin-left: -20px;
    box-shadow: 1px 1px 0px 0px rgba(33, 37, 41, 0.15);
    border-color: transparent #ffffff #ffffff transparent;
  }
  #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
    top: 35px !important;
  }
  #topnav .navigation-menu > .has-submenu.active .menu-arrow {
    top: 32px;
  }
  #topnav .navigation-menu > li .submenu {
    position: absolute;
    top: 100%;
    left: 15px;
    z-index: 1000;
    padding: 15px 0;
    list-style: none;
    min-width: 180px;
    visibility: hidden;
    opacity: 0;
    margin-top: 10px;
    transition: all 0.2s ease;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    padding: 10px 20px;
    clear: both;
    white-space: nowrap;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    font-weight: 600;
    color: #212529 !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    color: #396cf0 !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    white-space: nowrap;
    width: auto;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    overflow: hidden;
    min-width: 180px;
    display: inline-block;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    left: 100%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    left: 101%;
    top: 0;
    margin-left: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    border-color: #396cf0;
  }
  #topnav .navigation-menu > li:hover > a, #topnav .navigation-menu > li.active > a {
    color: #396cf0 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu > li.last-elements .submenu:before {
    left: auto;
    right: 10px;
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
    left: auto;
    right: 106%;
    margin-left: 0;
    margin-right: 10px;
  }
  #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu:before {
    transform: rotate(-45deg);
    right: -3px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgba(255, 255, 255, 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    border-color: #ffffff !important;
  }
  #topnav .navigation-menu.nav-right {
    justify-content: flex-end !important;
  }
  #topnav .navigation-menu.nav-left {
    justify-content: flex-start !important;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu {
    left: auto;
    right: 0;
  }
  #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
    right: -5px;
  }
  #topnav .logo {
    margin-right: 30px;
  }
  #topnav .dropdowns {
    padding-left: 15px;
    margin-left: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
  #topnav .search-bar .menu-search input[type=text], #topnav .search-bar .searchform input[type=text] {
    width: 250px !important;
  }
}
@media (max-width: 991px) {
  #topnav {
    background-color: #fff;
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    min-height: 74px;
  }
  #topnav .logo {
    padding: 3px 0 !important;
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
  }
  #topnav .navigation-menu {
    float: none;
  }
  #topnav .navigation-menu > li {
    float: none;
  }
  #topnav .navigation-menu > li .submenu {
    display: none;
    list-style: none;
    padding-left: 20px;
    margin: 0;
  }
  #topnav .navigation-menu > li .submenu li a {
    display: block;
    position: relative;
    padding: 7px 15px;
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.04em;
    font-weight: 600;
    color: #212529 !important;
    transition: all 0.3s;
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style: none;
    padding-left: 0;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    display: block;
    position: relative;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 2px;
    color: #8492a6;
  }
  #topnav .navigation-menu > li > a {
    color: #212529;
    padding: 10px 20px;
  }
  #topnav .navigation-menu > li > a:after {
    position: absolute;
    right: 15px;
  }
  #topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li .submenu li a:hover,
#topnav .navigation-menu > li.has-submenu.open > a {
    color: #396cf0;
  }
  #topnav .menu-extras .menu-item {
    border-color: #8492a6;
  }
  #topnav .navbar-header {
    float: left;
  }
  #topnav .dropdowns .login-btn-primary {
    display: inline-block !important;
  }
  #topnav .dropdowns .login-btn-light {
    display: none;
  }

  #topnav .has-submenu .submenu .submenu-arrow {
    transform: rotate(45deg);
    position: absolute;
    right: 20px;
    top: 12px;
  }
  #topnav .has-submenu.active a {
    color: #396cf0;
  }

  #navigation {
    position: absolute;
    top: 74px;
    left: 0;
    width: 100%;
    display: none;
    height: auto;
    padding-bottom: 0;
    overflow: auto;
    border-top: 1px solid #f2f4f6;
    border-bottom: 1px solid #f2f4f6;
    background-color: #fff;
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    right: 16px;
    top: 16px;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
  }
  #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
    visibility: visible;
    opacity: 1;
    margin-left: 0;
    margin-right: 0;
  }

  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .navbar-toggle .lines {
    margin-right: 0 !important;
  }
}
@media (max-width: 375px) {
  #topnav .dropdowns {
    margin: 0 0 0 10px;
  }
}
.sidebar-nav {
  padding: 15px 0;
}
.sidebar-nav > .navbar-item {
  padding: 6.5px 15px;
}
.sidebar-nav > .navbar-item .navbar-link {
  color: #212529 !important;
  font-size: 15px;
  font-weight: 500;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  font-size: 18px;
  margin-right: 6px;
}
.sidebar-nav > .navbar-item:hover .navbar-link, .sidebar-nav > .navbar-item.active .navbar-link {
  color: #396cf0 !important;
}

.navbar-white-bg {
  background-color: #fff !important;
}

.page-wrapper {
  height: 100vh;
}
.page-wrapper .sidebar-wrapper {
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  background-color: #fff;
  transition: all 0.3s ease;
  width: 300px;
  height: 100%;
  max-height: 100%;
  position: fixed;
  top: 0;
  left: -300px;
  z-index: 999;
}
@media (min-width: 1025px) {
  .page-wrapper .sidebar-wrapper {
    z-index: 1000;
  }
}
@media (max-width: 1024px) {
  .page-wrapper .sidebar-wrapper {
    left: 0;
  }
}
@media (max-width: 425px) {
  .page-wrapper .sidebar-wrapper {
    width: 260px;
  }
}
.page-wrapper .sidebar-wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.page-wrapper .sidebar-wrapper a {
  text-decoration: none;
}
.page-wrapper .sidebar-wrapper .sidebar-content {
  max-height: calc(100% - 30px);
  height: calc(100% - 30px);
  overflow-y: auto;
  position: relative;
}
.page-wrapper .sidebar-wrapper .sidebar-content.desktop {
  overflow-y: hidden;
}
.page-wrapper .sidebar-wrapper .sidebar-brand {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  height: 71px;
}
.page-wrapper .sidebar-wrapper .sidebar-brand > a {
  text-transform: uppercase;
  font-weight: bold;
  flex-grow: 1;
  transition: all 0.3s ease;
}
.page-wrapper .sidebar-wrapper .sidebar-brand > a:focus {
  outline: none;
}
.page-wrapper .sidebar-wrapper .sidebar-brand #close-sidebar {
  cursor: pointer;
  font-size: 24px;
  transition: all 0.3s ease;
}
.page-wrapper .sidebar-wrapper .sidebar-header {
  padding: 20px;
  overflow: hidden;
}
.page-wrapper .sidebar-wrapper .sidebar-menu {
  padding: 16px 0;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .header-menu span {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 20px 5px 20px;
  display: inline-block;
}
.page-wrapper .sidebar-wrapper .sidebar-menu li a {
  display: inline-block;
  width: 100%;
  text-decoration: none;
  position: relative;
  padding: 8px 24px 8px 20px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .page-wrapper .sidebar-wrapper .sidebar-menu li a {
    padding: 8px 24px 8px 24px;
  }
}
@media screen and (max-width: 425px) {
  .page-wrapper .sidebar-wrapper .sidebar-menu li a {
    padding: 8px 24px 8px 12px;
  }
}
.page-wrapper .sidebar-wrapper .sidebar-menu li a:hover > i::before {
  display: inline-block;
}
.page-wrapper .sidebar-wrapper .sidebar-menu li a:focus {
  outline: none;
}
.page-wrapper .sidebar-wrapper .sidebar-menu li a i {
  width: 36px;
  height: 36px;
  background-color: #f8f9fa;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  text-align: center;
  border-radius: 5px;
  transition: all 0.3s ease;
}
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover i, .page-wrapper .sidebar-wrapper .sidebar-menu li.active i {
  background-color: #f5f6f8;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  content: "\f0142" !important;
  color: #212529;
  font-family: "Material Design Icons";
  font-size: 14px;
  position: absolute;
  right: 15px;
  top: 17px;
  transition: all 0.3s ease;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(90deg);
  right: 17px;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown:hover > a:after, .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  color: #396cf0;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu {
  display: none;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul {
  padding: 5px 0;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li {
  padding-left: 25px;
  font-size: 14px;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
  content: "\f035f" !important;
  color: #212529;
  font-family: "Material Design Icons";
  margin-right: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a .badge,
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a .label {
  float: right;
  margin-top: 0px;
}
.page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.label, .page-wrapper .sidebar-wrapper .sidebar-menu ul li a span.badge {
  float: right;
  margin-top: 8px;
  margin-left: 5px;
}
.page-wrapper .sidebar-wrapper.sidebar-dark {
  background-color: #161c2d;
}
.page-wrapper .sidebar-wrapper.sidebar-colored {
  background-color: #396cf0;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .sidebar-colored, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .sidebar-colored {
  display: inline-block;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-dark-mode, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-dark-mode {
  display: none !important;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand .logo-light-mode, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand .logo-light-mode {
  display: none !important;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .btn.btn-icon, .page-wrapper .sidebar-wrapper.sidebar-dark .btn.btn-icon {
  background-color: rgba(248, 249, 250, 0.03) !important;
  color: rgba(248, 249, 250, 0.5) !important;
  border: none !important;
  box-shadow: none;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu {
  border-top-color: rgba(255, 255, 255, 0.03);
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a {
  color: rgba(255, 255, 255, 0.5);
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:active > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:active > a:before,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover > a:before,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active > a:before,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown.active > a,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-brand > a:hover, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:active > a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover > a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active > a:before,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown.active > a,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-brand > a:hover {
  color: #ffffff !important;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li a i, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li a i {
  background-color: rgba(248, 249, 250, 0.03);
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li:hover i, .page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu li.active i, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li:hover i, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu li.active i {
  background-color: rgba(248, 249, 250, 0.05);
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown > a:after, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown > a:after {
  color: rgba(255, 255, 255, 0.1);
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown:hover > a:after, .page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown.active > a:after, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown:hover > a:after, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown.active > a:after {
  color: #ffffff;
}
.page-wrapper .sidebar-wrapper.sidebar-colored .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before, .page-wrapper .sidebar-wrapper.sidebar-dark .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
  color: rgba(255, 255, 255, 0.5);
}
.page-wrapper .sidebar-wrapper .sidebar-header,
.page-wrapper .sidebar-wrapper .sidebar-menu {
  border-top: 1px solid #e9ecef;
}
.page-wrapper .sidebar-wrapper .sidebar-header .user-info .user-role,
.page-wrapper .sidebar-wrapper .sidebar-header .user-info .user-status,
.page-wrapper .sidebar-wrapper .sidebar-brand > a,
.page-wrapper .sidebar-wrapper .sidebar-menu li a {
  color: #161c2d;
  font-weight: 500;
}
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover > a,
.page-wrapper .sidebar-wrapper .sidebar-menu li.active > a,
.page-wrapper .sidebar-wrapper .sidebar-menu li:active > a,
.page-wrapper .sidebar-wrapper .sidebar-menu li:active > a:before,
.page-wrapper .sidebar-wrapper .sidebar-menu li:hover > a:before,
.page-wrapper .sidebar-wrapper .sidebar-menu li.active > a:before,
.page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a,
.page-wrapper .sidebar-wrapper .sidebar-header .user-info,
.page-wrapper .sidebar-wrapper .sidebar-brand > a:hover {
  color: #396cf0 !important;
}
.page-wrapper .sidebar-wrapper .sidebar-brand .sidebar-colored {
  display: none;
}
.page-wrapper .sidebar-wrapper .sidebar-brand .logo-dark-mode {
  display: none;
}
.page-wrapper .sidebar-wrapper .sidebar-brand .logo-light-mode {
  display: inline-block;
}
.page-wrapper .page-content {
  display: inline-block;
  width: 100%;
  padding-left: 0px;
  overflow-x: hidden;
  transition: all 0.3s ease;
}
.page-wrapper .page-content .top-header {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  max-height: 70px;
  left: 0px;
  transition: all 0.3s;
}
.page-wrapper .page-content .top-header .header-bar {
  background: #fff;
  height: 71px;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
}
@media (max-width: 425px) {
  .page-wrapper .page-content .top-header .header-bar {
    padding: 24px 12px;
  }
}
.page-wrapper .page-content .top-header .header-bar .logo-icon {
  display: none;
}
@media (max-width: 1024px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon {
    display: block;
  }
}
.page-wrapper .page-content .top-header .header-bar .logo-icon .small {
  display: none;
}
@media (max-width: 767px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon .small {
    display: block;
  }
}
.page-wrapper .page-content .top-header .header-bar .logo-icon .big {
  display: none;
}
.page-wrapper .page-content .top-header .header-bar .logo-icon .big .logo-dark-mode {
  display: none;
}
.page-wrapper .page-content .top-header .header-bar .logo-icon .big .logo-light-mode {
  display: inline-block;
}
@media (min-width: 768px) and (max-width: 1024px) {
  .page-wrapper .page-content .top-header .header-bar .logo-icon .big {
    display: block;
  }
}
.page-wrapper .page-content .top-header .header-bar .search-bar {
  padding-top: 14.5px;
  padding-bottom: 14.5px;
}
.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search form {
  position: relative;
}
.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search input[type=text], .page-wrapper .page-content .top-header .header-bar .search-bar .searchform input[type=text] {
  box-shadow: none;
  padding: 10px 12px;
  height: 40px;
  font-size: 14px;
  display: block;
  width: 250px;
  outline: none !important;
  padding-right: 45px;
  opacity: 0.7;
}
.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search input[type=submit], .page-wrapper .page-content .top-header .header-bar .search-bar .searchform input[type=submit] {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  width: 40px;
  height: 40px;
}
.page-wrapper .page-content .top-header .header-bar .search-bar .menu-search .searchform:after {
  content: "\f0349";
  position: absolute;
  font-family: "Material Design Icons";
  right: 14px;
  top: 10px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
.page-wrapper .page-content .layout-specing {
  padding: 94px 14px 24px !important;
  min-height: calc(100vh - 66px) !important;
  position: relative;
}
@media (max-width: 425px) {
  .page-wrapper .page-content .layout-specing {
    padding: 94px 0 24px !important;
  }
}
@media screen and (min-width: 1025px) {
  .page-wrapper.toggled .page-content {
    padding-left: 300px;
  }
}
.page-wrapper.toggled .top-header {
  left: 301px;
}
@media (max-width: 1024px) {
  .page-wrapper.toggled .top-header {
    left: 0;
  }
}
.page-wrapper.toggled .sidebar-wrapper {
  left: 0px;
}
@media (max-width: 1024px) {
  .page-wrapper.toggled .sidebar-wrapper {
    left: -300px;
  }
}

.sidebar-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 9px 24px 9px 20px !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  height: 58px;
}
@media (max-width: 425px) {
  .sidebar-footer {
    padding: 9px 12px !important;
  }
}

[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown > a:after {
  transform: rotate(-180deg);
}
[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown.active > a:after {
  transform: rotate(-90deg);
}
[dir=rtl] .page-wrapper .sidebar-wrapper .sidebar-menu .sidebar-dropdown .sidebar-submenu ul li a:before {
  transform: rotate(180deg);
}

footer {
  background: #202942;
  padding: 60px 0 24px;
  position: relative;
  color: #adb5bd;
}
footer .text-foot {
  color: #adb5bd;
}
footer .footer-head {
  font-size: 18px !important;
  letter-spacing: 1px;
  font-weight: 500;
  color: #fff;
}
footer .footer-list {
  margin-bottom: 0;
}
footer .footer-list li {
  margin-bottom: 10px;
}
footer .footer-list li a {
  transition: all 0.5s ease;
}
footer .footer-list li a:hover {
  color: #e6e8ea;
}
footer .footer-list li:last-child {
  margin-bottom: 0;
}
footer .footer-bar, footer.footer-bar {
  border-top: 1px solid #2a3657;
}

.bg-footer-color {
  background-color: #ffffff !important;
}

.avatar.avatar-ex-sm {
  height: 30px;
  width: 30px;
}
.avatar.avatar-ex-small {
  height: 36px;
  width: 36px;
}
.avatar.avatar-md-sm {
  height: 45px;
  width: 45px;
}
.avatar.avatar-small {
  height: 65px;
  width: 65px;
}
.avatar.avatar-md-md {
  height: 80px;
  width: 80px;
}
.avatar.avatar-medium {
  height: 110px;
  width: 110px;
}
.avatar.avatar-large {
  height: 140px;
  width: 140px;
}
.avatar.avatar-ex-large {
  height: 180px;
  width: 180px;
}
.avatar.avatar-client {
  max-height: 25px;
}

.accordion .accordion-item {
  overflow: hidden;
  background: transparent;
}
.accordion .accordion-item .accordion-button {
  font-weight: 600;
  text-align: left;
  box-shadow: none;
  padding: 1rem 2.5rem 1rem 1.25rem;
}
.accordion .accordion-item .accordion-button:focus {
  box-shadow: none;
}
.accordion .accordion-item .accordion-button:before {
  content: "\f0143";
  display: block;
  font-family: "Material Design Icons";
  font-size: 16px;
  color: #396cf0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.5s ease;
}
.accordion .accordion-item .accordion-button.collapsed {
  background-color: #ffffff;
  color: #212529 !important;
  transition: all 0.5s;
}
.accordion .accordion-item .accordion-button.collapsed:before {
  top: 15px;
  transform: rotate(180deg);
  color: #212529 !important;
}
.accordion .accordion-item .accordion-button:after {
  display: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 9999999;
}
#preloader #status {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#preloader #status .spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}
#preloader #status .spinner .double-bounce1, #preloader #status .spinner .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #396cf0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.form-label,
.form-check-label {
  font-weight: 500;
}

.form-control {
  border: 1px solid #e9ecef;
  font-size: 14px;
  line-height: 26px;
  border-radius: 6px;
  color: #212529 !important;
  text-align: left;
}
.form-control:focus {
  border-color: #396cf0;
  box-shadow: none;
}
.form-control[readonly] {
  background-color: #ffffff;
}
.form-control:disabled {
  background-color: #dee2e6;
}
.form-control::placeholder {
  color: #3c4858;
}

.form-check-input {
  border: 1px solid #e9ecef;
  background-color: #ffffff;
}
.form-check-input:focus {
  border-color: #396cf0;
  box-shadow: none;
}
.form-check-input.form-check-input:checked {
  background-color: #396cf0;
  border-color: #396cf0;
}

.subcribe-form .btn {
  padding: 10px 20px;
}
.subcribe-form input {
  padding: 12px 20px;
  width: 100%;
  color: #212529 !important;
  border: none;
  outline: none !important;
  padding-right: 160px;
  padding-left: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 50px;
}
.subcribe-form input::placeholder {
  color: #3c4858;
}
.subcribe-form button {
  position: absolute;
  top: 2px;
  right: 3px;
  outline: none !important;
}
.subcribe-form form {
  position: relative;
  max-width: 600px;
  margin: 0px auto;
}

.blog .title {
  transition: all 0.5s ease;
  font-weight: 500 !important;
}
.blog .title:hover {
  color: #396cf0 !important;
}
.blog .tag {
  background: #396cf0 !important;
}
.blog .link {
  color: #396cf0 !important;
}
.blog .post-meta .like:hover,
.blog .post-meta .comments:hover {
  color: #396cf0 !important;
}

.sidebar .widget .widget-search form {
  position: relative;
}
.sidebar .widget .widget-search input[type=text], .sidebar .widget .searchform input[type=text] {
  box-shadow: none;
  padding: 12px 15px;
  height: 45px;
  font-size: 14px;
  display: block;
  width: 100%;
  outline: none !important;
  padding-right: 45px;
}
.sidebar .widget .widget-search input[type=submit], .sidebar .widget .searchform input[type=submit] {
  position: absolute;
  top: 5px;
  right: 10px;
  opacity: 0;
  width: 40px;
  height: 40px;
}
.sidebar .widget .widget-search .searchform:after {
  content: "\f0349";
  position: absolute;
  font-family: "Material Design Icons";
  right: 16px;
  top: 15px;
  font-size: 20px;
  line-height: 20px;
  pointer-events: none;
}
.sidebar .widget .post-recent {
  padding-bottom: 15px;
}
.sidebar .widget .post-recent:last-child {
  padding-bottom: 0;
}
.sidebar .widget .post-recent .post-recent-thumb {
  width: 25%;
}
.sidebar .widget .post-recent .post-recent-content {
  width: 75%;
  padding-left: 10px;
}
.sidebar .widget .post-recent .post-recent-content a {
  display: block;
  color: #212529;
  font-size: 15px;
  transition: all 0.5s ease;
}
.sidebar .widget .post-recent .post-recent-content a:hover {
  color: #396cf0;
}
.sidebar .widget .post-recent .post-recent-content span {
  font-size: 13px;
}
.sidebar .widget .tagcloud > a {
  background: #f8f9fa;
  color: #212529;
  display: inline-block;
  font-size: 9px;
  letter-spacing: 1px;
  margin: 5px 10px 5px 0;
  padding: 8px 12px 7px;
  text-transform: uppercase;
  transition: all 0.5s ease;
  font-weight: 600;
}
.sidebar .widget .tagcloud > a:hover {
  background: #396cf0;
  color: #ffffff;
}

.pharpachy-categories .category-title {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.chat .chat-list:hover, .chat .chat-list.active, .chat .chat-list:focus {
  background: #f8f9fa !important;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
.chat .chat-list .on-off,
.chat .chat-type .on-off {
  position: absolute;
  left: -1px;
  top: -1px;
  font-size: 12px !important;
}
.chat.chat-person .on-off {
  font-size: 10px;
}
.chat.chat-person .chat-msg {
  margin-left: 8px;
}
.chat.chat-person .chat-msg .msg {
  border: 1px solid #e9ecef;
}
.chat.chat-person .chat-msg .msg-time {
  font-size: 12px !important;
}
.chat.chat-person .chat-right {
  justify-content: flex-end;
  text-align: right;
}
.chat.chat-person .chat-right .chat-user-image {
  order: 2;
}
.chat.chat-person .chat-right .chat-user-image .on-off {
  right: -1px;
}
.chat.chat-person .chat-right .chat-msg {
  margin-right: 8px;
  margin-left: 0;
}
.chat.chat-person .chat-right .chat-msg .msg {
  display: inline-block;
}
.chat.chat-person .chat-right .chat-msg .msg-time {
  display: block;
}

.map {
  line-height: 0;
}
.map iframe {
  width: 100%;
  height: 400px;
}

.error {
  margin: 8px 0px;
  display: none;
  color: #f0735a;
}

#ajaxsuccess {
  font-size: 16px;
  width: 100%;
  display: none;
  clear: both;
  margin: 8px 0px;
}

.error_message,
#success_page {
  padding: 10px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 5px;
}

.error_message {
  background-color: rgba(240, 115, 90, 0.1) !important;
  border: 2px solid rgba(240, 115, 90, 0.1) !important;
  color: #f0735a !important;
  font-size: 14px;
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgba(83, 199, 151, 0.1) !important;
  border: 2px solid rgba(83, 199, 151, 0.1) !important;
  color: #53c797 !important;
}
#success_page p {
  margin-bottom: 0 !important;
}

.play-btn {
  height: 78px;
  width: 78px;
  font-size: 30px;
  line-height: 76px;
  border-radius: 50%;
  display: inline-block;
  color: #ffffff;
}

.play-icon {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  right: 0;
  left: 0;
  text-align: center;
}
.play-icon i {
  height: 75px;
  -webkit-text-stroke: 2px #396cf0;
  -webkit-text-fill-color: transparent;
  width: 75px;
  font-size: 25px;
  line-height: 75px;
  display: inline-block;
  background-color: #fff;
}
.play-icon i:hover {
  -webkit-text-stroke: 2px #396cf0;
  -webkit-text-fill-color: #396cf0;
}

.cta-review {
  position: relative;
  top: 220px;
}

.features.feature-primary .title:hover {
  color: #396cf0 !important;
}
.features.feature-primary .icon {
  color: #396cf0 !important;
  background-color: rgba(57, 108, 240, 0.05) !important;
}
.features.feature-primary .link {
  color: #396cf0 !important;
}
.features.feature-bg-primary:hover {
  background-color: #396cf0 !important;
}
.features.feature-secondary .title:hover {
  color: #6c757d !important;
}
.features.feature-secondary .icon {
  color: #6c757d !important;
  background-color: rgba(108, 117, 125, 0.05) !important;
}
.features.feature-secondary .link {
  color: #6c757d !important;
}
.features.feature-bg-secondary:hover {
  background-color: #6c757d !important;
}
.features.feature-success .title:hover {
  color: #53c797 !important;
}
.features.feature-success .icon {
  color: #53c797 !important;
  background-color: rgba(83, 199, 151, 0.05) !important;
}
.features.feature-success .link {
  color: #53c797 !important;
}
.features.feature-bg-success:hover {
  background-color: #53c797 !important;
}
.features.feature-info .title:hover {
  color: #64c2f1 !important;
}
.features.feature-info .icon {
  color: #64c2f1 !important;
  background-color: rgba(100, 194, 241, 0.05) !important;
}
.features.feature-info .link {
  color: #64c2f1 !important;
}
.features.feature-bg-info:hover {
  background-color: #64c2f1 !important;
}
.features.feature-warning .title:hover {
  color: #f1b561 !important;
}
.features.feature-warning .icon {
  color: #f1b561 !important;
  background-color: rgba(241, 181, 97, 0.05) !important;
}
.features.feature-warning .link {
  color: #f1b561 !important;
}
.features.feature-bg-warning:hover {
  background-color: #f1b561 !important;
}
.features.feature-danger .title:hover {
  color: #f0735a !important;
}
.features.feature-danger .icon {
  color: #f0735a !important;
  background-color: rgba(240, 115, 90, 0.05) !important;
}
.features.feature-danger .link {
  color: #f0735a !important;
}
.features.feature-bg-danger:hover {
  background-color: #f0735a !important;
}
.features.feature-light .title:hover {
  color: #f8f9fa !important;
}
.features.feature-light .icon {
  color: #f8f9fa !important;
  background-color: rgba(248, 249, 250, 0.05) !important;
}
.features.feature-light .link {
  color: #f8f9fa !important;
}
.features.feature-bg-light:hover {
  background-color: #f8f9fa !important;
}
.features.feature-dark .title:hover {
  color: #212529 !important;
}
.features.feature-dark .icon {
  color: #212529 !important;
  background-color: rgba(33, 37, 41, 0.05) !important;
}
.features.feature-dark .link {
  color: #212529 !important;
}
.features.feature-bg-dark:hover {
  background-color: #212529 !important;
}
.features.feature-footer .title:hover {
  color: #202942 !important;
}
.features.feature-footer .icon {
  color: #202942 !important;
  background-color: rgba(32, 41, 66, 0.05) !important;
}
.features.feature-footer .link {
  color: #202942 !important;
}
.features.feature-bg-footer:hover {
  background-color: #202942 !important;
}
.features.feature-muted .title:hover {
  color: #8492a6 !important;
}
.features.feature-muted .icon {
  color: #8492a6 !important;
  background-color: rgba(132, 146, 166, 0.05) !important;
}
.features.feature-muted .link {
  color: #8492a6 !important;
}
.features.feature-bg-muted:hover {
  background-color: #8492a6 !important;
}

.features {
  transition: all 0.5s ease;
}
.features .icon {
  height: 65px;
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features .title {
  font-weight: 500;
}
.features .big-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  opacity: 0.05;
  font-size: 120px;
}
.features .titles,
.features .icons,
.features .para,
.features .big-icon {
  transition: all 0.5s ease;
}
.features:hover .titles,
.features:hover .icons,
.features:hover .big-icon {
  color: #ffffff !important;
}
.features:hover .para {
  color: rgba(255, 255, 255, 0.5) !important;
}

.bg-home {
  height: 100vh;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-260 {
  padding: 260px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-170 {
  padding: 170px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-half-150 {
  padding: 150px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-profile {
  padding: 80px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-dashboard {
  padding: 100px 0 30px;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.bg-hero {
  padding: 100px 0;
  background-size: cover;
  align-self: center;
  position: relative;
  background-position: center center;
}

.dr-profile-img {
  position: absolute;
  bottom: 0;
}
@media (max-width: 425px) {
  .dr-profile-img {
    position: relative;
    padding: 24px;
  }
}

.home-slider .carousel-control-next,
.home-slider .carousel-control-prev {
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 30px;
  border: 1px solid #ced4da;
}
.home-slider .carousel-control-next-icon,
.home-slider .carousel-control-prev-icon {
  width: 30%;
}
.home-slider .carousel-control-next {
  right: 8px;
}
.home-slider .carousel-control-prev {
  left: 8px;
}

.carousel-indicators [data-bs-target] {
  width: 7px;
  height: 7px;
  border-radius: 30px;
  background: rgba(57, 108, 240, 0.5) !important;
  transition: all 0.5s ease;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin: 0 4px;
  padding: 0;
}
.carousel-indicators .active {
  background: #396cf0 !important;
  border-color: #396cf0;
}

@media (max-width: 767px) {
  .bg-home, .bg-half-170, .bg-half-260, .main-slider .slides .bg-slider {
    padding: 150px 0;
    height: auto;
  }
}
.team {
  transition: all 0.5s;
}
.team .team-img .team-social {
  position: absolute;
  bottom: 10px;
  left: 10px;
  opacity: 0;
  transition: all 0.5s;
  transform: translateX(-50px);
}
.team .title {
  font-weight: 500;
}
.team .content,
.team .content .title,
.team .content .speciality {
  transition: all 0.5s;
}
.team .team-person img {
  transition: all 0.5s;
}
.team .team-person .team-like {
  position: absolute;
  top: 10px;
  right: 10px;
}
.team .team-person:hover img {
  transform: scale(1.05);
}
.team:hover .team-img .team-social {
  opacity: 1;
  transform: translateX(0px);
}
.team:hover .content {
  background: #396cf0 !important;
}
.team:hover .content .title {
  color: #ffffff !important;
}
.team:hover .content .speciality {
  color: rgba(255, 255, 255, 0.5) !important;
}

.section {
  padding: 100px 0;
  position: relative;
}
@media (max-width: 768px) {
  .section {
    padding: 60px 0;
  }
}

.bg-overlay {
  background-color: rgba(60, 72, 88, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.heading-title {
  line-height: 26px;
}
.heading-title .heading {
  font-size: 42px !important;
  letter-spacing: 1px;
}
.heading-title .title {
  font-size: 32px !important;
  letter-spacing: 1px;
}
.heading-title .para-desc {
  font-size: 17px;
}

.section-title {
  position: relative;
}
.section-title .title {
  letter-spacing: 0.5px;
  font-size: 24px !important;
}
.section-title .sub-title {
  letter-spacing: 0.5px;
  font-size: 28px !important;
}

.para-desc {
  max-width: 600px;
}

.mt-100 {
  margin-top: 100px;
}

.bg-white-50 {
  background: rgba(255, 255, 255, 0.5) !important;
}

.shape {
  position: absolute;
  pointer-events: none;
  right: 0;
  bottom: -2px;
  left: 0;
}
.shape > svg {
  transform: scale(2);
  width: 100%;
  height: auto;
  transform-origin: top center;
}
.shape.integration-hero {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  height: 250px;
  background: #ffffff;
}
@media (max-width: 768px) {
  .shape.integration-hero {
    height: 140px;
  }
}
@media (max-width: 425px) {
  .shape.integration-hero {
    height: 60px;
  }
}
@media (max-width: 425px) {
  .shape {
    bottom: -4px;
  }
}

.features-absolute {
  position: relative;
  margin: -200px 0 0px;
  z-index: 2;
  transition: all 0.5s ease;
}
@media (max-width: 768px) {
  .features-absolute {
    margin: -140px 0 0;
  }
}
.features-absolute.career-form {
  margin: -145px 0 0px;
}
@media (max-width: 768px) {
  .features-absolute.career-form {
    margin: -105px 0 0px;
  }
}

.feature-posts-placeholder {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  height: 66.6%;
}
@media (max-width: 425px) {
  .feature-posts-placeholder {
    height: 80%;
  }
}

@media (max-width: 768px) {
  .heading-title .heading {
    font-size: 36px !important;
  }
  .heading-title .para-desc {
    font-size: 18px;
  }

  .section-title .title {
    font-size: 24px !important;
  }

  .mt-60 {
    margin-top: 60px;
  }
}
.fea {
  stroke-width: 1.8;
}
.fea.icon-sm {
  height: 16px;
  width: 16px;
}
.fea.icon-ex-md {
  height: 20px;
  width: 20px;
}
.fea.icon-m-md {
  height: 28px;
  width: 28px;
}
.fea.icon-md {
  height: 35px;
  width: 35px;
}
.fea.icon-lg {
  height: 42px;
  width: 42px;
}
.fea.icon-ex-lg {
  height: 52px;
  width: 52px;
}

[class^=uil-]:before,
[class*=" uil-"]:before {
  margin: 0;
}

.uim-svg {
  vertical-align: 0 !important;
}

.rounded {
  border-radius: 5px !important;
}

.rounded-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-left {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.rounded-bottom {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-right {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-md {
  border-radius: 10px !important;
}

.rounded-lg {
  border-radius: 30px !important;
}

.border {
  border: 1px solid #e9ecef !important;
}

.border-top {
  border-top: 1px solid #e9ecef !important;
}

.border-bottom {
  border-bottom: 1px solid #e9ecef !important;
}

.border-left {
  border-left: 1px solid #e9ecef !important;
}

.border-right {
  border-right: 1px solid #e9ecef !important;
}

.back-to-top {
  position: fixed;
  z-index: 99;
  bottom: 30px;
  right: 30px;
  display: none;
  transition: all 0.5s ease;
  height: 36px;
  width: 36px;
  line-height: 33px;
  color: #fff !important;
}
.back-to-top:hover {
  background: #215aee !important;
}

.social-icon li a {
  color: #212529;
  border: 1px solid #212529;
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 30px;
  text-align: center;
  transition: all 0.4s ease;
  overflow: hidden;
  position: relative;
}
.social-icon li a .fea-social {
  stroke-width: 2;
}
.social-icon li a:hover {
  background-color: #396cf0;
  border-color: #396cf0 !important;
  color: #fff !important;
}
.social-icon li a:hover .fea-social {
  fill: #396cf0;
}
.social-icon.footer-social li a {
  color: #adb5bd;
  border-color: #283453;
}
.social-icon.social li a {
  color: #adb5bd;
  border-color: #adb5bd;
}

.back-to-home {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.sticky-bar {
  position: sticky;
  top: 80px;
}

.text-color-white {
  color: #fff !important;
}

.d-flex .flex-1 {
  flex: 1;
}

.margin-nagative.mt-n5 {
  margin-top: -48px;
}

.preview-box img {
  max-width: 100%;
  height: auto;
  border-radius: 6px;
}

.shop-list .shop-image .shop-icons,
.shop-list .shop-image .qty-icons {
  position: absolute;
  opacity: 0;
  transition: all 0.5s ease;
}
.shop-list .shop-image .shop-icons {
  top: 15px;
  left: 15px;
  z-index: 2;
}
.shop-list .shop-image .qty-icons {
  bottom: 15px;
  text-align: center;
  right: 0;
  left: 0;
  z-index: 2;
  transform: translateY(100px);
}
.shop-list .shop-image:hover .shop-icons,
.shop-list .shop-image:hover .qty-icons {
  opacity: 1;
}
.shop-list .shop-image:hover .qty-icons {
  transform: translateY(0);
}
.shop-list .content .product-name {
  transition: all 0.5s ease;
}
.shop-list .content .product-name:hover {
  color: #396cf0 !important;
}
.shop-list .label {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
}
.shop-list .qty-btn {
  pointer-events: none;
  width: 65px;
  padding-left: 16px;
}
@media (max-width: 1024px) {
  .shop-list .qty-btn {
    padding-left: 0;
  }
}

.switcher-btn {
  position: fixed;
  top: 200px;
  height: 41px;
  width: 40px;
  padding: 3px;
  border-radius: 0 30px 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-switcher .t-dark,
.style-switcher .t-rtl-light {
  display: inline-block;
}
.style-switcher .t-ltr-light,
.style-switcher .t-light,
.style-switcher .t-rtl-dark,
.style-switcher .t-ltr-dark {
  display: none;
}

@media only screen and (max-width: 375px) {
  .switcher-btn {
    display: none !important;
  }
}
[dir=rtl] .style-switcher .t-rtl-light {
  display: none;
}
[dir=rtl] .style-switcher .t-ltr-light {
  display: inline-block;
}

.fc-event-title-container,
#external-events .fc-event {
  cursor: pointer;
  margin: 4px 2px;
}

#external-events .fc-event .fc-event-main {
  padding: 5px;
}

.fc .fc-non-business {
  background-color: transparent;
}
.fc .fc-daygrid-day-number {
  padding: 5px;
  font-weight: 600;
  color: #396cf0;
}
.fc .fc-col-header-cell-cushion {
  padding: 8px 4px !important;
  color: #396cf0;
}
.fc .fc-scrollgrid-liquid {
  border-radius: 5px !important;
}

.fc-theme-standard .fc-scrollgrid,
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #e9ecef !important;
}

.fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  font-size: 18px !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-start {
  margin: auto 8px 4px;
}

.fc-event-main {
  background-color: #396cf0;
}

.fc-daygrid-dot-event {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
}
.fc-daygrid-dot-event:hover {
  background-color: #e9ecef;
}

.fc .fc-button {
  padding: 4px 8px;
  font-size: 13px !important;
  background-color: #396cf0 !important;
  border: 1px solid #396cf0 !important;
  color: #ffffff !important;
  text-transform: capitalize !important;
}
.fc .fc-button:disabled, .fc .fc-button:disabled:hover, .fc .fc-button:disabled:focus, .fc .fc-button:disabled:active, .fc .fc-button:disabled.active, .fc .fc-button:disabled.focus {
  background-color: rgba(57, 108, 240, 0.1) !important;
  border: 1px solid rgba(57, 108, 240, 0.1) !important;
  color: #396cf0 !important;
}
.fc .fc-button:hover, .fc .fc-button:focus, .fc .fc-button:active, .fc .fc-button.active, .fc .fc-button.focus {
  background-color: #215aee !important;
  border-color: #215aee !important;
}
.fc .fc-button:focus {
  box-shadow: none !important;
}
.fc .fc-icon {
  font-size: 16px !important;
}

.fc-h-event,
.fc-daygrid-event-dot {
  border-color: #396cf0;
  background-color: #396cf0;
}

#calendar {
  max-width: 1240px;
}

.simplebar-track.simplebar-vertical {
  width: 8px !important;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  width: 4px !important;
  background: #adb5bd !important;
}

.tns-nav {
  text-align: center;
  margin-top: 10px;
}
.tns-nav button {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: rgba(57, 108, 240, 0.5) !important;
  transition: all 0.5s ease;
  border: 2px solid rgba(255, 255, 255, 0.5);
  margin: 0 4px;
  padding: 0;
}
.tns-nav button.tns-nav-active {
  background: #396cf0 !important;
  border-color: #396cf0;
}

.tiny-timeline {
  position: relative;
  width: 100%;
  margin: 0 auto;
}
.tiny-timeline:before {
  content: "";
  position: absolute;
  width: 100%;
  top: 17px;
  left: 0;
  height: 2px;
  background: #396cf0;
}
.tiny-timeline .item-box {
  margin: 70px 15px;
}
.tiny-timeline .item-box:before, .tiny-timeline .item-box:after {
  content: "";
  position: absolute;
  left: 50%;
  background: #396cf0;
}
.tiny-timeline .item-box:before {
  width: 2px;
  height: 46px;
  top: -46px;
  transform: translateX(-50%);
}
.tiny-timeline .item-box:after {
  width: 13px;
  height: 13px;
  top: -59px;
  border-radius: 3px;
  transform: translateX(-50%) rotate(45deg);
}

.qs-datepicker-container {
  background-color: #fff;
  border-color: #e9ecef;
  color: #3c4858;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}
.qs-datepicker-container .qs-controls {
  background-color: #e9ecef;
  color: #3c4858;
}
.qs-datepicker-container .qs-controls .qs-month-year {
  font-weight: 600;
  font-size: 15px;
}
.qs-datepicker-container .qs-controls .qs-month-year:hover {
  border-bottom: 1px solid #e9ecef;
}
.qs-datepicker-container .qs-squares .qs-square {
  font-size: 15px;
  height: 34px;
  width: 34px;
}
.qs-datepicker-container .qs-squares .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(.qs-active):hover, .qs-datepicker-container .qs-squares .qs-square.qs-current {
  background: #396cf0;
  color: #ffffff;
  border-radius: 30px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 30px;
}
.qs-datepicker-container .qs-squares .qs-day {
  font-weight: 500;
  color: #8492a6;
}

.apexcharts-tooltip {
  background: #161c2d !important;
  color: #ffffff;
  border-color: #161c2d !important;
}

.apexcharts-gridlines-horizontal,
.apexcharts-gridlines-vertical,
.apexcharts-grid,
.apexcharts-gridline,
.apexcharts-xaxis-tick,
.apexcharts-xaxis line,
.apexcharts-gridline line,
.apexcharts-radar-series line,
.apexcharts-area-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-line-series .apexcharts-series-markers .apexcharts-marker.no-pointer-events,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon {
  pointer-events: none;
  stroke: #e9ecef;
}

.apexcharts-tooltip-title {
  background: #161c2d !important;
  color: #ffffff;
  border-color: #161c2d !important;
}

.apexcharts-data-labels, .apexcharts-datalabels text, .apexcharts-xaxis text, .apexcharts-yaxis text {
  fill: #212529 !important;
}

.apexcharts-legend-text {
  color: #212529 !important;
  font-family: var(--bs-font-sans-serif) !important;
}